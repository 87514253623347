<script>
import { Delete, Refresh } from '@element-plus/icons-vue'
import { computed } from 'vue'
import { CrudTagsSettings } from '!/components/crud/compositions/CrudTagsSettings'

export default {
  name: 'SyncTags',
  components: { Refresh, Delete },
  props: {
    dev2prod: {
      type: Boolean,
      default: false
    },
    versionedData: {
      type: Object,
      default: () => ({})
    },
    childrenEnvs: {
      type: Object,
      default: () => ({})
    },
    summaryTag: {
      type: Object,
      default: () => ({
        // '2removeOnProd': 100
      })
    }
  },
  emits: ['update:dev2prod'],
  setup(props) {
    return {
      tagsSettings: CrudTagsSettings(['dev2prod', '2removeOnProd']),
      syncTags: computed(() => {
        const tags = []
        if (props.summaryTag?.dev2prod || (props.versionedData.IsDev && !props.versionedData.IsProd)) {
          tags.push('dev2prod')
        }
        if (
          props.summaryTag?.['2removeOnProd'] ||
          (!props.versionedData.IsDev &&
          props.versionedData.IsProd &&
          props.childrenEnvs.childrenIsProd &&
          !props.childrenEnvs.childrenIsDev)
        ) {
          tags.push('2removeOnProd')
        }
        return tags
      })
    }
  }
}
</script>

<template>
  <div class="my-1 flex flex-wrap gap-y-1.5">
    <template
      v-for="(syncTag, index) in syncTags"
      :key="index"
    >
      <el-checkbox
        v-if="syncTag === 'dev2prod' && $store.getters['auth/userRights']?.editor"
        class="gs-checkbox-mini gs-font-scaled gs-height-related-sm mr-1.5"
        :class="[tagsSettings[syncTag].outlinedClasses]"
        border
        :model-value="$props[syncTag]"
        @update:model-value="$emit(`update:${syncTag}`, $event)"
      >
        <div class="leading-1 gs-font-scaled flex items-center">
          <span class="font-related-xs mr-1.5 whitespace-nowrap">{{ syncTag }}</span>
          <el-icon class="gs-scaled-icon-xss scale-90">
            <Refresh />
          </el-icon>
        </div>
      </el-checkbox>
      <div
        v-else
        class="gs-font-scaled gs-height-related-sm inline-flex items-center rounded border px-1.5 py-0"
        :class="[tagsSettings[syncTag].outlinedClasses]"
      >
        <span class="font-related-xs mr-1.5 whitespace-nowrap font-medium">
          {{ syncTag }}
        </span>
        <el-icon
          v-if="syncTag === 'dev2prod'"
          class="gs-scaled-icon-xss scale-90"
        >
          <Refresh />
        </el-icon>
        <el-icon
          v-else
          class="gs-scaled-icon-xss scale-90"
        >
          <Delete />
        </el-icon>
      </div>
    </template>
  </div>
</template>
