<template>
  <crud-details-page
    api="settings/suppress-error"
  >
    <template #form>
      <fields-col :sm="16">
        <crud-field-text
          api-field-name="Signature"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
