<script>
import { mapGetters } from 'vuex'
import { ArrowDownBold, ArrowUpBold, CircleClose, Search } from '@element-plus/icons-vue'
import { ref } from 'vue'
import FieldsLabel from '!/components/shared/FieldsLabel.vue'
import { AddNewByGlobalSearchProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import { globalProperties as app } from '!/plugins/utilities'
import { initDateMask, initDateTimeMask, initShortcutsDate, initShortcutsDateTime } from '!/composition/dateTimePickerExtensions'

export default {
  name: 'CrudTypeFields',
  components: { CircleClose, FieldsLabel },
  mixins: [AddNewByGlobalSearchProps],
  props: {
    typeValueField: {
      // any | int | uint | bool
      type: String,
      default: 'any'
    },
    valueField: {
      type: null,
      default: null
    },
    prefixIcon: {
      type: null,
      default: undefined
    },
    searchIcon: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    label: {
      type: [String, Boolean],
      default: ''
    },
    classLabel: {
      type: String,
      default: ''
    },
    smallLabel: {
      type: Boolean,
      default: true
    },
    mediumLabel: {
      type: Boolean,
      default: false
    },
    largeLabel: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: undefined
    },
    /** options used by selectors (array of {value: '', label: '',}) */
    options: {
      type: Array,
      default: undefined
    },
    /** when passed options used by selector are loaded from api (see vuex store name enums) */
    optionsEnum: {
      type: String,
      default: undefined
    },
    apiOptions: {
      type: Object,
      default: undefined
    },
    focus: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: [String, Number],
      default: 0
    },
    initValue: {
      type: undefined,
      default: undefined
    }
  },
  emits: ['update:value-field', 'change'],
  setup(props) {
    const entityComponentData = {}
    if (props.typeValueField === 'entity') {
      entityComponentData.selector = app.$utils.getRouteByEntity(props.apiOptions?.entity)?.meta?.selector
      if (entityComponentData.selector !== false) {
        entityComponentData.selector = entityComponentData.selector || null
      }
      if (!entityComponentData.selector && entityComponentData.selector !== false) {
        app.$notify({
          title: 'Error',
          type: 'warning',
          customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
          duration: 9000,
          message: `Selector mapping with entity (${props.apiOptions?.entity}) is missing. Please report to developer.`
        })
      }
    }
    const pickerDateTime = ref(null)
    const { initMaskDateTime, inputDateTimeFocused } = initDateTimeMask(pickerDateTime)
    const pickerDate = ref(null)
    const { initMaskDate, inputDateFocused } = initDateMask(pickerDate)

    return {
      icons: {
        Search,
        ArrowDownBold,
        ArrowUpBold
      },
      entityComponent: entityComponentData?.selector,
      isEntityObject: !!props.valueField?.ID,
      initMaskDateTime,
      pickerDateTime,
      inputDateTimeFocused,
      pickerDate,
      initMaskDate,
      inputDateFocused
    }
  },
  data() {
    return {
      error: '',
      input: undefined,
      isFocused: false
    }
  },
  computed: {
    fieldModel: {
      get() {
        if (
          (this.input?.length && this.error.length && this.valueField === undefined) ||
          ((this.valueField === null || this.valueField === undefined) && this.initValue !== undefined)
        ) {
          this.reset()
          if (this.initValue !== undefined) {
            return this.initValue
          }
        }
        return this.error.length ? this.input : this.valueField
      },
      set(val) {
        this.input = val
        switch (this.typeValueField) {
          case 'uint':
          case 'int':
          case 'float':
            this.checkNumberValue(this.input)
            break
        }
        if (!this.error.length) {
          switch (this.typeValueField) {
            case 'date':
              this.$emit('update:value-field', val ? `${val}T00:00:00Z` : val)
              break
            default:
              this.$emit('update:value-field', this.input)
          }
        }
        this.$emit('change')
      }
    },
    fieldIcon() {
      return this.prefixIcon || this.searchIcon ? Search : undefined
    },
    selectOptions() {
      return this.options || this.$store.getters['enums/getEnum'](this.optionsEnum || this.apiOptions?.enum) || []
    },
    disabledStepUp() {
      return (
        !!this.$attrs.disabled ||
        !!this.error.length ||
        (this.$attrs.max !== undefined ? this.fieldModel + (this.$attrs?.step || 1) > this.$attrs.max : false)
      )
    },
    disabledStepDown() {
      const min = this.$attrs.min !== undefined ? this.$attrs.min : this.typeValueField === 'uint' ? 0 : undefined
      return (
        !!this.$attrs.disabled ||
        !!this.error.length ||
        (min !== undefined ? this.fieldModel - (this.$attrs?.step || 1) < min : false)
      )
    },
    ...mapGetters({
      userScaledWidth: 'auth/userScaledWidth',
      userScaledSize: 'auth/userScaledSize'
    }),
    scaleClass() {
      const classesMap = {
        small: undefined,
        default: 'scale-110',
        large: 'scale-125'
      }
      return classesMap?.[this.$store.getters['auth/userScaledSize']]
    }
  },
  created() {
    if (this.initValue && (this.fieldModel === null || this.fieldModel === undefined)) {
      this.fieldModel = this.initValue
    }
    this.shortcutsDateTime = initShortcutsDateTime(this, 'fieldModel')
    this.shortcutsDate = initShortcutsDate(this, 'fieldModel')
  },
  mounted() {
    if (this.focus && this.$refs?.focusTarget) {
      this.$nextTick(() => {
        if (!this.isFocused) {
          this.$refs?.focusTarget?.focus?.()
          this.onFocusSelect()
        }
      })
    }
  },
  methods: {
    checkNumberValue(val) {
      const type = this.typeValueField
      const isNumber = /^-?\d*(?:[.,]\d*)?$/.test(val)
      if (isNumber || !val?.length) {
        const stringVal = val === undefined || val === null ? '' : `${val}`
        const numberVal = stringVal.replace(',', '.') * 1
        const min = this.$attrs?.min === undefined ? 0 : this.$attrs.min * 1
        const max = this.$attrs?.max === undefined ? undefined : this.$attrs.max * 1
        // eslint-disable-next-line regexp/no-super-linear-backtracking
        if (type === 'uint' && val?.length && (!/^[^-]\d*[^.,]?\d*$/.test(val) || val === ',' || val === '.')) {
          this.error = val.includes('-') ? 'only positive' : 'not decimal'
        } else if (
          type === 'int' &&
            val?.length &&
          // eslint-disable-next-line regexp/no-super-linear-backtracking
            (!/^-?\d+[^.,]?\d*$/.test(val) || val === ',' || val === '.')
        ) {
          this.error = val === '-' ? 'only numbers' : 'not decimal'
        } else if (val === '-' || val === ',' || val === '.') {
          this.error = 'only numbers'
        } else if (this.$attrs?.min !== false && numberVal < min) {
          this.error = `min: ${min}`
        } else if (max !== undefined && numberVal > max) {
          this.error = `max: ${max}`
        } else {
          this.error = ''
        }
      } else {
        this.error = 'only numbers'
      }
      if (!this.error && val?.includes?.(',')) {
        this.input = val.replace(',', '.')
      }
    },
    reset() {
      this.$utils.nextLoopEvent(100).then(() => {
        this.input = ''
        this.error = ''
        if (this.initValue) {
          this.fieldModel = this.initValue
        }
      })
    },
    clear() {
      if (this.$attrs.disabled) {
        return
      }
      this.fieldModel = undefined
    },
    onChangeStep(up = true) {
      if (this.$attrs.disabled) {
        return
      }
      if (this.fieldModel === undefined || this.fieldModel === null) {
        this.fieldModel = this.$attrs?.min || 0
        return
      }
      if (up) {
        this.fieldModel = this.fieldModel * 1 + (this.$attrs?.step || 1)
      } else {
        this.fieldModel = this.fieldModel * 1 - (this.$attrs?.step || 1)
      }
    },
    onBlurField() {
      this.isFocused = false
    },
    onFocusSelect() {
      this.isFocused = true
      this.$utils.nextLoopEvent(50).then(() => {
        if (this.isFocused) {
          this.$refs?.focusTarget?.select?.()
        }
      })
    }
  }
}
</script>

<template>
  <FieldsLabel
    :label="label"
    class="gs-field"
    style="padding-bottom: 3px"
    :small="smallLabel"
    :medium="mediumLabel"
    :large="largeLabel"
    :class-label="classLabel"
  >
    <!-- int, uint type -->
    <div
      v-if="['int', 'uint', 'float'].includes(typeValueField)"
      class="gs-input-number relative"
      :class="{ isError: error.length }"
    >
      <div class="relative inline-block">
        <el-input
          ref="focusTarget"
          v-model="fieldModel"
          :name="name"
          :size="userScaledSize"
          type="text"
          :prefix-icon="fieldModel ? undefined : fieldIcon"
          v-bind="$attrs"
          :tabindex="tabindex"
          :style="`max-width: ${fullWidth ? '100%;' : `${150 + userScaledWidth * 2}px`}`"
          @keydown.down.stop
          @keydown.up.stop
          @blur="onBlurField"
          @focus="onFocusSelect"
        />
        <div class="invisible absolute top-0 right-0 bottom-0 leading-none">
          <div class="h-1/2">
            <el-button
              :icon="icons.ArrowUpBold"
              :disabled="disabledStepUp"
              size="small"
              class="block h-full p-0 leading-none"
              @dblclick.stop
              @click.stop="onChangeStep"
            />
          </div>
          <div class="h-1/2">
            <el-button
              :icon="icons.ArrowDownBold"
              :disabled="disabledStepDown"
              size="small"
              class="block h-full p-0 leading-none"
              @dblclick.stop
              @click.stop="onChangeStep(false)"
            />
          </div>
        </div>
      </div>
      <el-icon
        v-if="fieldModel || fieldModel === 0"
        class="invisible absolute cursor-pointer bg-white"
        @click.stop="clear"
      >
        <CircleClose />
      </el-icon>
      <div
        class="error-box overflow-hidden"
        style="max-width: 90%"
      >
        <div class="truncate">
          {{ error }}
        </div>
      </div>
    </div>
    <!-- bool type -->
    <div v-else-if="typeValueField === 'bool'">
      <el-select
        ref="focusTarget"
        v-model="fieldModel"
        :name="name"
        clearable
        :size="userScaledSize"
        placeholder=" "
        v-bind="$attrs"
        :tabindex="tabindex"
        :style="`max-width: ${fullWidth ? '100%;' : `${80 + userScaledWidth}px`}`"
      >
        <el-option
          label="true"
          :value="true"
        />
        <el-option
          label="false"
          :value="false"
        />
      </el-select>
    </div>
    <!-- select type -->
    <div v-else-if="['select', 'enum'].includes(typeValueField)">
      <el-select
        ref="focusTarget"
        v-model="fieldModel"
        collapse-tags
        filterable
        clearable
        placeholder=" "
        v-bind="$attrs"
        :tabindex="tabindex"
        :size="userScaledSize"
        :style="`max-width: ${fullWidth ? '100%;' : `${200 + userScaledWidth * 2}px`}`"
      >
        <el-option
          v-for="item in selectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <!-- entity type -->
    <div v-else-if="typeValueField === 'entity' && entityComponent">
      <component
        :is="entityComponent"
        v-bind="$attrs"
        :id="id"
        v-model="fieldModel"
        :tabindex="tabindex"
        filter-mode
        :full-width="fullWidth"
        :model-is-entity="isEntityObject"
        :field="field"
        :size="userScaledSize"
        :focus-on-init="focus"
      />
    </div>
    <!-- else datetime -->
    <div v-else-if="typeValueField === 'datetime'">
      <el-date-picker
        ref="pickerDateTime"
        v-model="fieldModel"
        v-bind="$attrs"
        :tabindex="tabindex"
        class="gs-field gs-toggle-prefix-icon max-w-full"
        :class="{ gs_is_value: !!fieldModel || inputDateTimeFocused }"
        type="datetime"
        :size="userScaledSize"
        format="YYYY-MM-DD HH:mm:ss"
        value-format="YYYY-MM-DDTHH:mm:ss[Z]"
        :popper-class="scaleClass"
        :shortcuts="shortcutsDateTime"
        @focus.once="initMaskDateTime"
        @change.once="initMaskDateTime"
      />
    </div>
    <!-- else date -->
    <div v-else-if="typeValueField === 'date'">
      <el-date-picker
        ref="pickerDate"
        v-model="fieldModel"
        v-bind="$attrs"
        :tabindex="tabindex"
        class="gs-field gs-toggle-prefix-icon max-w-full"
        type="date"
        :class="{ gs_is_value: !!fieldModel || inputDateFocused }"
        :size="userScaledSize"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        :popper-class="scaleClass"
        :shortcuts="shortcutsDate"
        @focus.once="initMaskDate"
        @change.once="initMaskDate"
      />
    </div>
    <!-- input  -->
    <el-input
      v-else-if="!$slots.default"
      ref="focusTarget"
      v-model="fieldModel"
      :name="name"
      :size="userScaledSize"
      :style="`max-width: ${fullWidth ? '100%;' : `${200 + userScaledWidth * 2}px`}`"
      :prefix-icon="fieldModel ? undefined : fieldIcon"
      :tabindex="tabindex"
      v-bind="$attrs"
      clearable
      @blur="onBlurField"
      @focus="onFocusSelect"
    />
    <slot />
  </FieldsLabel>
</template>

<style scoped>
.el-input-number.is-controls-right .el-input__inner {
  padding-left: 3px;
  padding-right: 20px;
}
.gs-input-number {
  .el-icon.invisible {
    top: -3px;
    left: -2px;
  }
  &:hover {
    .invisible {
      visibility: visible !important;
    }
  }
}
:deep(.el-select) {
  input {
    margin-left: 4px !important;
  }
  .el-tag {
    font-size: 10px;
    padding: 0 2px;
    .el-select__tags-text {
      justify-content: flex-start;
      max-width: 50px !important;
    }
  }
}
:deep(.isError) {
  .el-input__wrapper {
    box-shadow: 0 0 0 1px var(--el-color-danger) inset;
  }
  .el-input__inner {
    color: var(--el-color-danger);
  }
  .error-box {
    position: absolute;
    bottom: -3px;
    left: 5px;
    background-color: white;
    font-size: 9px;
    line-height: 1em;
    color: var(--el-color-danger);
    padding: 0 1px;
  }
}
:deep(.gs-toggle-prefix-icon) {
  &.gs_is_value {
    .el-input__prefix {
      display: none !important;
    }
  }
}
</style>
